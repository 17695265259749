import {Button, Dropdown, Modal} from "antd";
import AppService from "../services/AppService";
import Title from "antd/lib/typography/Title";


export default function EmergencyCall({mini}) {
    async function makeCall(phoneNumber, name) {

        Modal.confirm({
            title: "Êtes-vous sûr de vouloir appeler : \n" + name + " - " + phoneNumber + " ?",
            cancelText: "Annuler",
            okText: "Oui c'est une urgence !",
            okType: "danger",
            centered: true,
            onOk: () => {
                console.log("MakeCall", phoneNumber, name)
                AppService.get("emergency.call", {phoneNumber: phoneNumber})
            }
        });

    }


    let phoneItems = [
        {
            number: "17",
            label: "Police",
        },
        {
            number: "15",
            label: "SAMU",
        },
        {
            number: "18",
            label: "Pompiers",
        },
        {
            number: "0800182626",
            label: "SPCJ",
        }
    ]


    return (
        <Dropdown placement={mini ? "top" : "bottom"} overlayStyle={{border: "1px solid #000", borderRadius: 10}}
                  trigger={"click"} menu={{
            items: phoneItems.map((item, index) => ({
                key: index,
                label: (
                    <a onClick={() => makeCall(item.number, item.label)}>
                        <Title style={{
                            textAlign: "center",
                            fontSize: 20,
                            paddingBottom: 15,
                            borderBottom: "1px solid #000"
                        }}>{item.label}</Title>
                    </a>
                ),
            }))
        }}>
            <Button size={mini ? "middle" : "large"} danger type="primary" block style={{overflow: "hidden"}}>Appel
                d'urgence</Button>
        </Dropdown>
    )
}