import React, {Fragment} from "react";
import PaiementModal from "../modals/PaiementModal";
import {Button, Input, Space, Tag, Typography} from "antd";
import AmountModal from "../modals/AmountModal";
import {connect} from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";

import {withIdleTimer} from 'react-idle-timer'
import EmergencyCall from "../components/EmergencyCall";
import {ASSET_BASE_URL} from "../configs/AppConfig";


const {Title, Text} = Typography

const responsiveCarousel = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

const defaultButtons = [
    {
        text: "1€",
        montant: 1
    },
    {
        text: "2€",
        montant: 2
    },
    {
        text: "5€",
        montant: 5
    },
    {
        text: "10€",
        montant: 10
    },
    {
        text: "26€",
        montant: 26
    },
    {
        text: "52€",
        montant: 52
    },
]

const TIMEOUT = 2 * 60 * 1000

class Main extends React.Component {

    initialState = {
        index: 0
    }

    state = this.initialState

    componentDidMount() {
        console.log("componentDidMount")
        this.checkIndex()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("componentDidUpdate")
        this.checkIndex()
    }

    checkIndex() {
        console.log("checkIndex")
        const items = this.getItems()
        const {index} = this.state

        if (index + 1 > items.length) {
            this.carousel?.goToSlide(items.length - 1)
            return this.setState({index: items.length - 1})
        }
    }

    reset() {
        console.log("RESET CONFIG")
        this.setState(this.initialState)
        this.carousel?.goToSlide(0)
        this.timeout = setTimeout(() => this.reset(), TIMEOUT)
    }

    initPaiement(amount) {
        const item = this.getItems()[this.state.index]
        this.paiementModal.init({type: item.type, typeId: item.typeId, amount: amount})
    }

    getCaisses(auto = true) {
        const org = this.props.org

        let _caisses = org.caisses.filter((c) => (c.payed < c.objectif || !c.withObjectif) && c.active && !c.paused)
        if (auto) {
            _caisses = _caisses.filter(c => !c.hidden).sort((a, b) => b.withObjectif - a.withObjectif)
        }

        return _caisses
    }


    getItems() {
        const org = this.props.org
        const borne = this.props.borne

        const mainLabel = org?.web["don.main.label"] || "Caisse Principale"

        const generalItem = {id: null, name: mainLabel, cerfa: true}

        let items = [{...generalItem}, ...this.getCaisses()]
        if (borne?.caisseSystem === "MANUAL") {
            let _items = []
            if (Array.isArray(borne?.manualCaisses)) {
                //console.log("manualCaisses",borne?.manualCaisses)

                let _caissesSource = [{...generalItem}, ...this.getCaisses(false)]
                for (let mc of borne?.manualCaisses) {
                    const _item = _caissesSource.find(i => i.id === mc)
                    if (_item) {
                        _items.push(_item)
                    }
                }
            }
            items = _items
            if (items.length === 0) {
                items = [{...generalItem}]
            }
        }

        return items
    }


    render() {
        const org = this.props.org
        const borne = this.props.borne

        const items = this.getItems()

        let item = items[this.state.index]

        console.log("SelectedItem", item)

        let presetButtons = defaultButtons

        if (Array.isArray(borne?.defaultPresetButtons) && borne.defaultPresetButtons.length > 0) {
            presetButtons = borne.defaultPresetButtons
        }

        if (item && Array.isArray(item.presetButtons) && item.presetButtons.length > 0) {
            presetButtons = item.presetButtons
        }

        let emergencyType = borne?.emergencyType || "SHABBAT"


        return (
            <div style={{position: "relative", height: "100vh", width: "100%", overflow: "hidden"}}>

                <img
                    src={borne?.customWallpaper ? ASSET_BASE_URL + "/" + borne.customWallpaper : "/assets/image/bg.png"}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        height: "100%",
                        width: "100%",
                        objectFit: "cover"
                    }}/>


                <div style={{backgroundColor: "rgba(0,0,0,0.5)", height: "100vh", width: "100%", paddingTop: 50}}>
                    <IdleTimer
                        timeout={TIMEOUT}
                        onAction={() => console.log("onAction")}
                        onActive={() => console.log("onActive")}
                        //onIdle={()=>console.log("onIdle")}
                        onIdle={() => this.reset()}
                    />


                    {emergencyType === "ALL" && (
                        <div style={{
                            position: "absolute",
                            top: "87vh",
                            width: "100vw",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <div style={{width: "60vw"}}>
                                <EmergencyCall mini/>
                            </div>
                        </div>
                    )}


                    <Title level={3} style={{opacity: items.length > 1 ? 1 : 0, color: "#FFF", textAlign: "center"}}>Sélectionnez
                        une cause :</Title>


                    <div style={{
                        opacity: items.length > 1 ? 1 : 0,
                        display: "flex",
                        width: "100vw",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <div style={{height: 3, width: "80%", backgroundColor: "#FFF"}}/>
                    </div>


                    <div style={{
                        position: "relative",
                        marginTop: 30,
                        paddingBottom: 30,
                    }}>

                        <Carousel
                            ref={(el) => (this.carousel = el)}
                            responsive={responsiveCarousel}
                            showDots
                            renderDotsOutside

                            beforeChange={(index) => {
                                console.log("slide index", index)
                                const item = items[index]
                                this.setState({index: index, type: item.itemType, typeId: item.id})
                            }}
                        >

                            {items.map(c => {

                                if (c.id && c.withObjectif) {

                                    var percent = 0
                                    var displayReste = 0
                                    var displayTarget = 0

                                    if (c && c.withObjectif) {
                                        displayTarget = c.objectif + c.marge
                                        var displayTotal = c.total + c.marge
                                        displayReste = displayTotal < displayTarget ? displayTarget - displayTotal : 0
                                        percent = displayTotal * 100 / displayTarget

                                        if (c.smart) {
                                            displayTarget = c.objectif
                                            displayReste = parseFloat(c.reste).toFixed(2)
                                            if (c.reste > c.objectif) {
                                                percent = 0
                                            }
                                        }
                                    }

                                    return (
                                        <div style={{
                                            position: "relative",
                                            margin: "0 10px",
                                            height: 150,
                                            backgroundColor: "rgba(255,255,255,0.3)",
                                            borderRadius: 10,
                                            border: "3px solid #FFF",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 10
                                        }}>

                                            <Tag color={c.cerfa ? "purple-inverse" : "orange-inverse"} style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0
                                            }}>{c.cerfa ? "Avec" : "Sans"} Reçu Fiscal</Tag>


                                            <Title level={5}
                                                   style={{color: "#FFF", textAlign: "center"}}>{c.name}</Title>


                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                gap: 10
                                            }}>

                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "row",
                                                    gap: 20
                                                }}>
                                                    <Text style={{
                                                        color: "#FFF",
                                                        textAlign: "center"
                                                    }}>Objectif: {displayTarget}€</Text>

                                                    <Text style={{
                                                        color: "#FFF",
                                                        textAlign: "center"
                                                    }}>Reste: {displayReste}€</Text>
                                                </div>


                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "row",
                                                    gap: 20
                                                }}>
                                                    <ProgressBar
                                                        customLabel={<span/>}
                                                        completed={percent}
                                                        width={"60vw"}
                                                        height={"10px"}
                                                        bgColor={"#FFF"}
                                                        baseBgColor={"rgba(255,255,255,0.2)"}
                                                        className="cagnotte-progress-bar"
                                                    />

                                                    <Text style={{
                                                        color: "#FFF",
                                                        textAlign: "center"
                                                    }}>{parseInt(percent)}%</Text>
                                                </div>

                                            </div>

                                        </div>
                                    )

                                } else {
                                    return (
                                        <div style={{
                                            position: "relative",
                                            margin: "0 10px",
                                            height: 150,
                                            backgroundColor: "rgba(255,255,255,0.3)",
                                            borderRadius: 10,
                                            border: "3px solid #FFF",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <Tag color={c.cerfa ? "purple-inverse" : "orange-inverse"} style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0
                                            }}>{c.cerfa ? "Avec" : "Sans"} Reçu Fiscal</Tag>

                                            <Title level={3}
                                                   style={{color: "#FFF", textAlign: "center"}}>{c.name}</Title>
                                        </div>
                                    )
                                }
                            })}

                        </Carousel>


                    </div>


                    <div style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20
                    }}>
                        <div style={{height: 3, width: "90%", backgroundColor: "#FFF"}}/>
                    </div>

                    <div style={{
                        marginTop: 30,
                        padding: "0 15px",
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        rowGap: 15,
                        columnGap: 15
                    }}>

                        {presetButtons.map(i => {
                            return (
                                <div
                                    style={{
                                        height: 50,
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#FFF",
                                        borderRadius: 8,
                                        fontWeight: "600"
                                    }}
                                    onClick={() => this.initPaiement(i.montant * 100)}
                                >
                                    {i.text}
                                </div>
                            )
                        })}

                    </div>


                    <div style={{
                        margin: "0 10px",
                        marginTop: 20,

                        height: 50,
                        backgroundColor: "rgba(255,255,255,0.3)",
                        borderRadius: 10,
                        border: "3px solid #FFF",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                         onClick={() => this.amountModal.show()}
                    >
                        <span style={{color: "#FFF", textAlign: "center", fontWeight: "600", fontSize: 20}}>Autre Montant</span>
                    </div>


                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100vw",
                        paddingBottom: 20
                    }}>
                        <img src={"/assets/image/tsedasmart-white.png"} style={{height: 40}}/>
                    </div>


                    <AmountModal getRef={(ref) => this.amountModal = ref} onFinish={(amount) => {
                        console.log("Custom Amount", amount)
                        this.initPaiement(amount)
                    }}/>

                    <PaiementModal getRef={(ref) => this.paiementModal = ref} onFinish={() => {
                        this.props?.refreshData()
                        this.reset()
                    }}/>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        org: state.data.org,
        borne: state.data.borne,
    }
}

export default connect(mapStateToProps)(Main)


class IdleTimerComponent extends React.Component {
    render() {
        return this.props.children
    }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent)