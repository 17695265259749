import {createSlice} from '@reduxjs/toolkit'


export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        borne: null,
        org: null,
    },
    reducers: {
        setData: (state, action) => {
            state.borne = action.payload.borne
            state.org = action.payload.organisation
        },
    },
})

// Action creators are generated for each case reducer function
export const {setData} = dataSlice.actions

export default dataSlice.reducer