import axios from 'axios';
import {API_BASE_URL} from '../configs/AppConfig';

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})


// API Request interceptor
service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
    return response.data
}, (error) => {

    console.log("FETCH error ", error)

    return Promise.reject(error);
});

export default service
