import React from "react";
import ApiService from "../services/ApiService";
import AppService from "../services/AppService";
import {Button, Modal, QRCode, Typography} from "antd";

import {CloseOutlined} from "@ant-design/icons"

import Lottie from 'react-lottie';
import * as animationCardTpe from '../assets/lottie/card-tpe.json'
import {connect} from "react-redux";


const {Title, Text} = Typography


const PAIEMENT_INIT_TIMEOUT = 1 * 60 * 1000
const LINK_INIT_TIMEOUT = 2 * 60 * 1000

class PaiementModal extends React.Component {

    constructor(props) {
        super(props);
        if (props.getRef) {
            props.getRef(this)
        }
    }

    initialState = {
        show: false,
        link: null,
        paiement: null,
        processing: false,
        text: null,
        errored: false
    }

    state = this.initialState

    clearTimeouts(){
        clearTimeout(this.closeTimeout)
        clearTimeout(this.linkTimeout)
        clearTimeout(this.paiementTimeout)
    }

    init(config) {
        this.clearTimeouts()
        this.setState({...this.initialState, show: true})
        this.makePaiement(config)
    }

    componentWillUnmount() {
        this.clearTimeouts()
        this.getIntervalStatusRef && this.getIntervalStatusRef();
    }

    onClose() {
        this.clearTimeouts()
        this.getIntervalStatusRef && this.getIntervalStatusRef();

        this.setState(this.initialState)
        if (this.state.link && this.props.onFinish) {
            this.props.onFinish()
        }
    }


    async makePaiement(config) {
        clearTimeout(this.paiementTimeout)
        this.paiementTimeout = setTimeout(() => {
            console.log("PAIEMENT SESSION TIMEOUT")
            this.cancelPaiement()
        }, PAIEMENT_INIT_TIMEOUT)

        this.setState({text: "Initialisation du paiement..."})

        const borneId = window.BORNEID
        const apiResult = await ApiService.post("/paiement/init/" + borneId, config)
        console.log(apiResult)

        if (!apiResult.success) {
            alert("paiement/init " + apiResult.error)
            return this.onClose()
        }

        this.setState({text: "Vous pouvez utiliser votre carte sur le terminal."})

        //APP READER
        if (!window.ISWEB) {
            const result = await AppService.get("terminal.paiement", {secret: apiResult.secret})
            console.log("terminal.paiement", result, result.error)

            if (!result.success) {
                if(result.error?.includes("NETWORK")){
                    return await AppService.get("app.restart")
                }

                this.setState({errored: true, text:"Terminal :\n" + result.error})

                if (result.error !== "USER_ERROR.CANCELED")
                {
                    //alert(result.error)
                }

                //return this.onClose()

                this.closeTimeout=setTimeout(() => {
                    console.log("CLOSE TIMEOUT")
                    this.onClose()
                }, 5 * 1000)

                return false
            }
        }


        //WEB READER
        if (window.ISWEB) {
            const result = await ApiService.getInterval("/paiement/webStatus/" + apiResult.paiementId, null, (ref => this.getIntervalStatusRef = ref))
            console.log("webterminal.paiement", result, result.error)

            if (!result.success) {
                this.setState({errored: true, text: result.error + ":\n" + result.message})

                if (result.error !== "customer_canceled" && result.error !== "SESSION_TIMEOUT") {
                    //alert("webterminal.paiement "+result.error)
                }

                setTimeout(() => {
                    this.cancelPaiement()
                    this.onClose()
                }, 5 * 1000)

                return false
            }
        }

        this.setState({processing: true})
        clearTimeout(this.paiementTimeout)

        const postApiResult = await ApiService.get("/post-paiement/link/" + apiResult.paiementId)
        console.log(postApiResult)

        this.setState({link: postApiResult.link, paiement: postApiResult.paiement})

        this.linkTimeout = setTimeout(() => {
            console.log("LINK SESSION TIMEOUT")
            this.onClose()
        }, LINK_INIT_TIMEOUT)
    }


    async cancelPaiement() {
        if (!window.ISWEB) {
            const res = await AppService.get("terminal.cancel")
            if (res.success) {
                this.onClose()
            }
        }

        if (window.ISWEB) {
            const borneId = window.BORNEID
            const res = await ApiService.get("/paiement/webCancel/" + borneId)
            console.log("cancelWebPaiement", res)
            if (res.success) {
                this.onClose()
            }
        }
    }


    render() {

        return (
            <Modal open={this.state.show} closable={false} footer={null}>
                <div style={{height: "70vh"}}>

                    {!this.state.link && (
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            <Title level={5} style={{textAlign: "center", height: "10vh"}}>{this.state.text}</Title>


                            {!this.state.errored && (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "0px solid red",
                                    height: "50vh"
                                }}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            auotplay: true,
                                            animationData: animationCardTpe,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={250}
                                        width={"100%"}
                                    />
                                </div>
                            )}


                            {this.state.errored && (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "0px solid red",
                                    height: "50vh"
                                }}>
                                    <img
                                        src={'/assets/image/close.png'}
                                        style={{height: 150, width: "100%"}}
                                    />
                                </div>
                            )}


                            {!this.state.processing && (
                                <Button onClick={() => this.cancelPaiement()} danger style={{overflow:"hidden"}}>{this.state.errored?"Terminer":"Annuler"}</Button>
                            )}
                        </div>
                    )}


                    {this.state.link && (
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                border: "0px solid red",
                                height: "20vh",
                                textAlign: "center"
                            }}>
                                <Title level={4}>La suite, c'est sur votre téléphone !</Title>

                                <Text>Afin
                                    de {this.state.paiement?.cerfa ? "recevoir votre Reçu Fiscal" : "completer votre paiement"},
                                    scannez ce QR-code sur l'application « Appareil photo » de votre téléphone.</Text>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "0px solid red", height: "45vh"
                            }}>
                                <QRCode
                                    errorLevel={"H"}
                                    value={this.state.link}
                                    size={250}
                                    iconSize={250 / 3}
                                    icon={this.props.org.logo}
                                />
                            </div>

                            <Button onClick={() => this.onClose()} type={"primary"}>Terminer</Button>
                        </div>
                    )}

                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        org: state.data.org
    }
}

export default connect(mapStateToProps)(PaiementModal)
