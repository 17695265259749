const defaultService = {}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


var ACKREQUESTS = []
var LISTENERS = []

function onMessage(payload) {
    //console.log("ONMESSAGE",payload)

    const type = payload.type
    const session = payload.session

    if (session) {
        var ackIndex = ACKREQUESTS.findIndex(i => i.session == session)
        if (ackIndex !== -1) {
            ACKREQUESTS[ackIndex].callback(payload)
            ACKREQUESTS.splice(ackIndex, 1)
        }
    } else {
        for (var listener of LISTENERS) {
            if (listener.type == type) {
                listener.callback(payload)
            }
        }
    }

}


function sendMessage(payload) {
    if (!window.ReactNativeWebView) {
        alert("Unable to communicate with native app")
        return false
    }

    console.log(window.ReactNativeWebView.postMessage)

    //console.log("POST MESSAGE",payload)

    var res = window.ReactNativeWebView.postMessage(JSON.stringify(payload))

    console.log(res)

    return true
}

function sendWithACK(type, params) {
    const session = uuidv4()

    const payload = {type: type, session: session, data: params}

    return new Promise((resolve, reject) => {
        let timeout = setTimeout(() => reject({success: false, error: "SESSION TIMEOUT"}), 60 * 1000)

        ACKREQUESTS.push({
            session: session, callback: (result) => {
                clearTimeout(timeout)
                const data = result.data
                //console.log("ACK DATA",data)
                resolve(data)
            }
        })

        if (!sendMessage(payload)) {
            reject({success: false, error: "SEND ERROR"})
        }
    })
}


defaultService.sendMessage = sendMessage

defaultService.init = () => {
    if (window.ISWEB) {
        return true
    }

    if (window.ISAPP) {

        window.addEventListener("message", function (event) {
            try {
                onMessage(JSON.parse(event.data));
            } catch (e) {
            }
        });


        document.addEventListener("message", function (event) {
            try {
                onMessage(JSON.parse(event.data));
            } catch (e) {
            }
        });

        sendMessage({type: "init", data: {}})

        return true
    }

    console.error("Unable to connect with app bridge")
    window.location.reload()

    return false
}


defaultService.addEventListener = function (type, callback) {

    const id = uuidv4()

    const remove = () => {
        const index = LISTENERS.findIndex(i => i.id == id)
        if (index === -1) {
            throw new Error("Unable to remove listener " + id + " " + type)
        }
        LISTENERS.splice(index, 1)
    }

    LISTENERS.push({
        id: id,
        type: type,
        callback: callback
    })

    return remove
}


defaultService.get = async function (type, params = {}) {

    try {
        return await sendWithACK(type, params)
    } catch (error) {
        console.error(error)
        return error
    }

}

export default defaultService
