import {useEffect, useRef, useState} from "react";
import AppService from "./services/AppService";
import Main from "./views/Main";
import ApiService from "./services/ApiService";
import {setData} from "./redux/data";
import {useSelector, useDispatch} from 'react-redux'

import {useClearCacheCtx} from 'react-clear-cache';
import {Button, ConfigProvider, Dropdown, Modal} from "antd";
import {PhoneOutlined} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import EmergencyCall from "./components/EmergencyCall"
import {ENV} from "./configs/AppConfig";

let intervalTime = 2 * 60 * 1000

if (ENV === "DEV") {
    intervalTime = 10 * 1000
}

function App() {
    const [isShabbat, setIsShabbat] = useState(localStorage.getItem("SHABBAT") === "1" || false)

    //RELOAD UPDATE
    const {isLatestVersion, emptyCacheStorage} = useClearCacheCtx();
    if (!isLatestVersion) {
        emptyCacheStorage()
    }

    const [inited, setInited] = useState(false)
    const dispatch = useDispatch()

    const getData = async () => {
        const borneId = window.BORNEID

        console.log("getData", borneId)

        const res = await ApiService.get("/id/" + borneId)
        if (res.success) {
            dispatch(setData({borne: res.data, organisation: res.organisation}))
            setInited(true)
        }
    }

    useEffect(() => {
        if (!window.ISWEB) {
            AppService.init()

            AppService.addEventListener("shabbat.state", (_data) => {
                const bool = _data.data
                console.log("shabbat.state", bool)
                localStorage.setItem("SHABBAT", bool ? "1" : "0")
                setIsShabbat(bool)
            })
        }

        getData()
        const interval = setInterval(() => getData(), intervalTime)
        return () => {
            clearInterval(interval)
        }
    }, []);


    if (!inited) {
        return <span
            style={{color: "#FFF", marginTop: 100, marginLeft: 30}}>Initialisation de la borne - {window.BORNEID}</span>
    }

    if (isShabbat) {
        return <ShabbatMode/>
    }

    return (
        <Main refreshData={() => getData()}/>
    );
}

export default App;


function ShabbatMode() {

    const borne = useSelector(state => state.data.borne)

    let screenId = borne?.shabbatScreen || 2

    let emergencyType = borne?.emergencyType || "SHABBAT"

    return (
        <ConfigProvider
            theme={{
                components: {
                    Dropdown: {
                        paddingBlock: 0
                    },
                },
            }}
        >
            <div style={{
                position: "relative",
                height: "100vh",
                width: "100vw",
                backgroundColor: "#000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <video src={"/assets/video/chabbat/" + screenId + ".mp4"} muted autoPlay loop
                       style={{position: "absolute", top: 0, left: 0, height: "100%", width: "100%"}}/>

                {emergencyType !== "NONE" && (
                    <div style={{
                        position: "absolute",
                        top: "40vh",
                        width: "100vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{width: "80vw"}}>
                            <EmergencyCall/>
                        </div>
                    </div>
                )}

            </div>

        </ConfigProvider>
    )
}