import fetch from '../auth/FetchInterceptor'


const defaultService = {}

defaultService.get = async function (url, params) {
    try {
        return await fetch({
            url: url,
            method: 'get',
            params
        })
    } catch (e) {
        console.log("Axios Error", e, e.code)
        if (e.code === "ERR_NETWORK") {
            return defaultService.get(url, params)
        }
    }
}

defaultService.getInterval = async function (url, params, removeFunction, interval = 2 * 1000, timeout = 60 * 1000) {
    let timedOut = false
    setTimeout(() => {
        timedOut = true
    }, timeout)

    if (removeFunction) {
        removeFunction(() => {
            console.log("getInterval removeFunction")
            timedOut = true
        })
    }

    while (true) {
        if (timedOut) {
            return {success: false, error: "SESSION_TIMEOUT"}
        }

        try {
            const result = await fetch({
                url: url,
                method: 'get',
                params
            })

            if (!result.wait) {
                return result
            } else {
                await new Promise(r => setTimeout(r, interval));
            }
        } catch (e) {
            console.log("getInterval Axios Error", e, e.code)
            if (e.code !== "ERR_NETWORK") {
                await new Promise(r => setTimeout(r, interval));
            }
        }
    }
}

defaultService.post = async function (url, data) {
    try {
        return await fetch({
            url: url,
            method: 'post',
            data: data
        })
    } catch (e) {
        console.log("Axios Error", e, e.code)
        if (e.code === "ERR_NETWORK") {
            return defaultService.post(url, data)
        }
    }
}

export default defaultService
