import React from "react";
import ApiService from "../services/ApiService";
import AppService from "../services/AppService";
import {Button, Modal, QRCode, Space, Typography} from "antd";

import {CloseOutlined} from "@ant-design/icons"

import Lottie from 'react-lottie';
import * as animationCardTpe from '../assets/lottie/card-tpe.json'
import {connect} from "react-redux";


const {Title, Text} = Typography


const TIMEOUT = 1 * 60 * 1000

export default class AmountModal extends React.Component {

    constructor(props) {
        super(props);
        if (props.getRef) {
            props.getRef(this)
        }
    }

    initialState = {
        show: false,
        textMontant: ""
    }

    state = this.initialState

    addNumber(n) {
        const textMontant = this.state.textMontant + (n?.toString() || "")
        if (textMontant === "0") {
            return false
        }
        this.setState({textMontant: textMontant})
    }

    delNumber() {
        this.setState({textMontant: this.state.textMontant.slice(0, -1)})
    }


    show() {
        this.timeout = setTimeout(() => this.onClose(), TIMEOUT)
        this.setState({show: true})
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    onClose() {
        clearTimeout(this.timeout)
        this.setState(this.initialState)
        if (this.state.textMontant && this.props.onFinish) {

            const montant = parseInt(this.state.textMontant)
            if (!montant || montant < 1) {
                return false
            }

            this.props.onFinish(montant * 100)
        }
    }


    render() {

        return (
            <Modal open={this.state.show} centered closable onCancel={() => this.onClose()} footer={null}>
                <div style={{height: "80vh"}}>

                    <div style={{
                        marginTop: 50,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <input
                            value={this.state.textMontant ? this.state.textMontant + "€" : "0€"}
                            style={{
                                marginBottom: 50,
                                textAlign: "center",
                                fontSize: 40,
                                backgroundColor: "transparent",
                                outline: "none",
                                border: 0,
                                color: "#000",
                                maxWidth: "100%"

                            }}
                            readOnly
                            unselectable
                        />

                        <Space direction={"vertical"} size={"middle"}>
                            {[["1", "2", "3"], ["4", "5", "6"], ["7", "8", "9"], [null, "0", "DEL"]].map(g => {
                                return (
                                    <Space size="large" style={{marginTop: 5}}>
                                        {g.map(i => {

                                            if (i === 'DEL') {
                                                if (!this.state.textMontant) {
                                                    return null
                                                }

                                                return (
                                                    <img
                                                        onClick={() => this.delNumber()}
                                                        src={"/assets/icons/delete.png"}
                                                        style={{marginLeft: 15}}
                                                    />
                                                )
                                            }

                                            return (
                                                <div
                                                    onClick={() => this.addNumber(i)}
                                                    shape="round"
                                                    style={{
                                                        border: "1px solid #000",
                                                        backgroundColor: "#FFF",
                                                        borderRadius: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        opacity: i === null ? 0 : 1,
                                                        fontSize: 40,
                                                        height: 65,
                                                        width: 65,
                                                        padding: 0,
                                                        outline: "none"
                                                    }}
                                                >
                                                    {i}
                                                </div>
                                            )
                                        })}
                                    </Space>
                                )
                            })}
                        </Space>


                        <Button
                            size={"large"}
                            type={"primary"}
                            onClick={() => this.onClose()}
                            style={{marginTop: 30}}
                        >
                            Valider
                        </Button>

                    </div>

                </div>
            </Modal>
        )
    }
}
